<template>
  <div class="orders-page">
    <a-spin :spinning="loading" :tip="labels.view.loading">
      <div v-if="order" class="content-base">
        <a-row type="flex" justify="space-between" :gutter="[16, 16]">
          <a-col>
            <h2 class="ticket-view-title">
              {{ labels.view.playDetails }}{{ order.number }}
            </h2>
          </a-col>
          <a-col>
            <a-button-group>
              <a-button
                type="danger"
                icon="arrow-left"
                ghost
                @click="handleCancel"
              >
                <span>{{ labels.view.goBack }}</span>
              </a-button>
              <a-button
                class="btn-print"
                type="primary"
                icon="printer"
                @click="print"
                >{{ labels.view.print }}</a-button
              >
              <a-button
                v-if="canCancel && order.status !== 'cancelled' && !order.paid"
                class="btn-cancel"
                type="danger"
                icon="close-circle"
                @click="confirmCancel"
                >{{ labels.view.cancel }}</a-button
              >
            </a-button-group>
          </a-col>
        </a-row>
        <a-divider :style="{ marginTop: 0 }" />
        <a-row type="flex" justify="center" :gutter="[16, 16]">
          <a-col :xs="24" :sm="24" :md="20" :lg="18">
            <a-card class="ticket-view-card" id="ticket-print-area">
              <a-row type="flex" justify="center" :gutter="[16, 16]">
                <a-col :xs="24">
                  <h3 class="ticket-view-card-title">
                    {{ labels.appName.toUpperCase() }}
                  </h3>
                  <h4 class="ticket-view-card-subtitle">
                    {{ labels.guaranteePayment }}
                  </h4>
                </a-col>
              </a-row>
              <a-row type="flex" justify="center" :gutter="[16, 16]">
                <a-col :xs="24" :md="12">
                  <span class="ticket-view-card-label"
                    >{{
                      order.user.isSeller
                        ? labels.view.sellerLabel.toUpperCase()
                        : labels.view.clientLabel.toUpperCase()
                    }}:
                  </span>
                  <span
                    v-if="order?.external && order?.buyer_name"
                    class="ticket-view-card-value"
                    >{{ order.buyer_name }} ({{ order.user.firstname }}
                    {{ order.user.lastname }})</span
                  >
                  <span v-else class="ticket-view-card-value"
                    >{{ order.user.firstname }} {{ order.user.lastname }}</span
                  >
                </a-col>
                <a-col
                  :xs="24"
                  :md="12"
                  :style="{ textAlign: isMobile ? 'left' : 'right' }"
                >
                  <span class="ticket-view-card-label">{{
                    labels.view.dateLabel
                  }}</span>
                  <span class="ticket-view-card-value">{{
                    order.date | formatDate
                  }}</span>
                </a-col>
              </a-row>
              <a-row type="flex" justify="center" :gutter="[16, 16]">
                <a-col :xs="24" :md="12">
                  <span class="ticket-view-card-label">{{
                    labels.view.phoneLabel
                  }}</span>
                  <span class="ticket-view-card-value">{{
                    order.user.whatsapp | VMask("(###) ###-####")
                  }}</span>
                </a-col>
                <a-col
                  :xs="24"
                  :md="12"
                  :style="{ textAlign: isMobile ? 'left' : 'right' }"
                >
                  <span class="ticket-view-card-label">{{
                    labels.view.ticketNoLabel
                  }}</span>
                  <span class="ticket-view-card-value">{{ order.number }}</span>
                </a-col>
              </a-row>
              <a-row
                v-if="order.status === 'cancelled'"
                type="flex"
                justify="end"
                :gutter="[16, 16]"
              >
                <a-col
                  :xs="24"
                  :md="12"
                  :style="{ textAlign: isMobile ? 'left' : 'right' }"
                >
                  <span class="ticket-view-card-label">{{
                    labels.view.statuses.label
                  }}</span>
                  <span class="ticket-view-card-value">
                    <a-tag color="red">
                      {{ labels.view.statuses[order.status] | ucfirst }}
                    </a-tag>
                  </span>
                </a-col>
              </a-row>
              <a-row type="flex" justify="center" :gutter="[16, 16]">
                <a-col :span="24">
                  <a-divider />
                  <a-table
                    v-if="customDetails.length"
                    :dataSource="customDetails"
                    :pagination="false"
                    :columns="columns"
                    :rowKey="'id'"
                    :size="'small'"
                    :bordered="false"
                    :class="'ticket-component-table'"
                    :defaultExpandAllRows="true"
                    :expandIcon="expandIcon"
                    :expandRowByClick="false"
                    :rowClassName="
                      (row) =>
                        `table-row ${
                          row.isLottery ? 'table-row-disabled-hover' : ''
                        } ${row.won_amount > 0 ? 'table-row-winning' : ''}`
                    "
                  />
                  <a-divider />
                </a-col>
              </a-row>
              <a-row type="flex" justify="space-between" :gutter="[16, 16]">
                <a-col>
                  <span class="ticket-view-card-total-label">{{
                    labels.view.totalLabel.toUpperCase()
                  }}</span>
                </a-col>
                <a-col>
                  <span class="ticket-view-card-total-value"
                    >${{ order.total_amount | formatNumber(2, ",", ".") }}</span
                  >
                </a-col>
              </a-row>
              <a-row
                v-if="order.won_total_amount > 0"
                type="flex"
                justify="space-between"
                :gutter="[16, 16]"
              >
                <a-col>
                  <span class="ticket-view-card-total-label">{{
                    labels.view.wonTotalLabel.toUpperCase()
                  }}</span>
                </a-col>
                <a-col>
                  <span class="ticket-view-card-total-value"
                    >${{
                      order.won_total_amount | formatNumber(2, ",", ".")
                    }}</span
                  >
                </a-col>
              </a-row>
              <a-row type="flex" justify="center" :gutter="[16, 16]">
                <a-col :xs="24" :style="{ paddingTop: '60px' }">
                  <h3 class="ticket-view-card-title small">
                    {{ labels.view.checkTicketLabel }}
                  </h3>
                  <h4 class="ticket-view-card-title small">
                    {{ labels.view.noPaymentLabel }}
                  </h4>
                </a-col>
              </a-row>
            </a-card>
          </a-col>
        </a-row>
      </div>
    </a-spin>
  </div>
</template>
<script>
import _ from "lodash";
import labels from "@/utils/labels";
import { mapActions, mapGetters, mapState } from "vuex";
import accounting from "accounting";
import { adminPageTitle } from "@/utils/utils";
import moment from "moment";
import * as actions from "@/utils/actions";
import {
  DATE_FORMAT,
  generateUniqueID,
  hasRoles,
  roles,
} from "../../../utils/utils";
export default {
  name: "OrderView",
  metaInfo: {
    title: adminPageTitle(labels.orders.singular),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.orders.singular,
      },
    ],
  },
  data() {
    return {
      labels: { ...labels.orders, appName: labels.appName },
      loading: false,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.handleFetchOrder(this.$route.params.id);
    } else {
      this.$router.push({ name: "admin.orders" });
    }
  },
  computed: {
    ...mapState("layout", ["screenWidth"]),
    ...mapState("orders", ["order"]),
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    canCancel() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_ORDERS_CANCEL]);
    },
    isMobile() {
      return this.screenWidth < 592;
    },
    customDetails() {
      const { details = [] } = this.order;
      //NOTE: GROUP BY LOTTERY AND SECOND LOTTERY
      const lotteries = Object.values(
        _.cloneDeep(details).reduce((acc, play) => {
          const lottery = play.lottery;
          const secondLottery = play.secondLottery;
          const key = secondLottery?.id
            ? `${lottery.id}-${secondLottery.id}`
            : lottery.id;
          if (!acc[key]) {
            acc[key] = {
              id: this.uuid(),
              lottery,
              secondLottery,
              isLottery: true,
              children: [],
            };
          }
          acc[key].children.push(play);
          return acc;
        }, {})
      );
      return lotteries;
    },
    columns() {
      return [
        {
          title: this.isMobile
            ? this.labels.view.table.typeShort.toUpperCase()
            : this.labels.view.table.type.toUpperCase(),
          dataIndex: "type",
          key: "type",
          width: "70px",
          customRender: (text, record) => {
            if (record?.isLottery) {
              const lotteryName = record.secondLottery?.id
                ? `${record.lottery.name} - ${record.secondLottery.name}`
                : record.lottery.name;
              return {
                children: <span class="text-hover">{lotteryName}</span>,
                attrs: {
                  colSpan: this.columns.length,
                },
              };
            }
            return this.$options.filters.ucfirst(text);
          },
        },
        {
          title: this.labels.view.table.numbers.toUpperCase(),
          dataIndex: "numbers",
          key: "numbers",
          width: "60px",
          align: "center",
          customRender: (numbers, record) => {
            if (record.isLottery) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return numbers;
          },
        },
        {
          title: this.labels.view.table.amount.toUpperCase(),
          dataIndex: "amount",
          key: "amount",
          width: "60px",
          align: "right",
          customRender: (amount, record) => {
            if (record.isLottery) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return accounting.formatNumber(amount, 2, ",", ".");
          },
        },
        {
          title: this.labels.view.table.wonAmount.toUpperCase(),
          dataIndex: "won_amount",
          key: "won_amount",
          width: "60px",
          align: "right",
          hidden: this.order?.won_total_amount === 0,
          customRender: (amount, record) => {
            if (record.isLottery) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return accounting.formatNumber(amount, 2, ",", ".");
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions("orders", ["fetchOrder", "cancelOrder"]),
    uuid() {
      return generateUniqueID();
    },
    handleCancel() {
      this.$router.push({ name: "admin.orders" });
    },
    expandIcon({ expandable }) {
      return expandable ? "" : " ";
    },
    countRecordByLottery(lottery) {
      return _.cloneDeep(this.ticket.details).filter(
        (play) => play.lottery.id === lottery.id
      ).length;
    },
    async handleFetchOrder(id) {
      try {
        this.loading = true;
        await this.fetchOrder(id);
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        if (error.isAxiosError && error.response.status === 404) {
          this.$router.push({ name: "admin.orders" });
        }
      } finally {
        this.loading = false;
      }
    },
    async print() {
      const options = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        styles: ["/print.css"],
        timeout: 1000,
        autoClose: false,
        windowTitle: window.document.title,
      };
      await this.$htmlToPaper("ticket-print-area", options);
    },
    async handleCancelOrder() {
      try {
        this.loading = true;
        await this.cancelOrder(this.order.id);
        this.$message.success(
          this.labels.view.cancelConfirmation.successCancellation
        );
      } catch (error) {
        this.$message.error(
          error.message || this.labels.view.cancelConfirmation.errorCancellation
        );
      } finally {
        this.loading = false;
      }
    },
    confirmCancel() {
      this.$confirm({
        title: this.labels.view.cancelConfirmation.title,
        content: this.labels.view.cancelConfirmation.content.replace(
          "{code}",
          this.order.number
        ),
        okText: this.labels.view.cancelConfirmation.okText,
        okType: "danger",
        cancelText: this.labels.view.cancelConfirmation.cancelText,
        onOk: () => {
          this.handleCancelOrder();
        },
      });
    },
  },
  filters: {
    formatDate(value) {
      return moment(value).format(DATE_FORMAT.MOMENT_DATE_TIME);
    },
  },
  beforeDestroy() {
    this.$store.commit(`orders/${actions.GET_ORDER}`, null);
    this.loading = false;
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.orders-page {
  .list {
    &-title {
      margin-bottom: 10px;
      .list-icon {
        margin-right: 10px;
      }
    }
    &-subtitle {
      margin-bottom: 10px;
    }
  }
  .add-button {
    color: color(primary);
    border-color: color(primary);
    margin-left: 10px;
  }
  .action-buttons {
    display: flex;
    justify-content: space-between;
    .action-button-view {
      color: color(primary);
    }
    .action-button-cancel {
      color: color(danger);
    }
  }
  .ticket-view-card {
    margin-top: 20px;
    &-title {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: #000;
      text-align: center;
      line-height: 1.2;
      &.small {
        font-size: 1rem;
      }
    }
    &-subtitle {
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 20px;
      color: #000;
      text-align: center;
    }
    &-label {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: #000;
      text-align: center;
    }
    &-value {
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: #000;
      text-align: center;
      border-bottom: 1px dotted #000;
    }
    &-total-label {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: #000;
      text-align: center;
    }
    &-total-value {
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: #000;
      text-align: center;
      border-bottom: 1px dashed #000;
    }
  }
  .ant-table {
    tr.table-row-winning,
    tr.table-row-winning:hover {
      td:nth-child(1) {
        border-left: 10px solid color(success) !important;
      }
    }
  }
}
</style>
